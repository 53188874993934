<template>
  <div class="content__body">
    <div class="detail">
      <div class="detail__wrapper">
        <div class="detail__col">
          <div class="detail__row">
            <span>ID:</span> {{ order.id }}
          </div>
          <div class="detail__row">
            <span>Статус 1С:</span> {{ order.status === 0 ? 'Успех' : order.status === 1 ? 'Требует внимания' : 'Ошибка' }}
          </div>
        </div>
        <div class="detail__col">
          <div class="detail__row">
            <span>Сумма:</span> {{ order.total_sum }} ₽
          </div>
          <div class="detail__row">
            <span>Клиент:</span> <button @click="goToClientDetail(order.client.id)" type="button">{{ order.client.name }}</button>
          </div>
        </div>
        <div class="detail__col">
          <div class="detail__row">
            <span>Контрагент:</span> {{ order.counterparty.name }} ({{ order.counterparty.code_1c }})
          </div>
          <div class="detail__row">
            <span>Пользователь:</span>
            &nbsp; <span v-if="order.user === null" class="detail__empty">Пользователь удален</span>
            &nbsp; <router-link v-else-if="order.user.user_type === 'A'" :to="{ name: 'admins-detail', params: { id: order.user.id } }">{{ order.user.fio }}</router-link>
            &nbsp; <router-link v-else-if="order.user.user_type === 'M'" :to="{ name: 'managers-detail', params: { id: order.user.id } }">{{ order.user.fio }}</router-link>
            &nbsp; <button v-else @click="goToUserDetail(order.client.id, order.user.id)" type="button">{{ order.user.fio }}</button>
          </div>
        </div>
      </div>
      <div class="detail__row">
        <span>Номер заказа 1С: </span>
        <ul v-for="(code, index) in order.codes_1c" :key="code">
          <li>{{ code }}{{ index !== order.codes_1c.length - 1 ? ',' : '' }}</li>
        </ul>
      </div>
      <div v-if="order.comment" class="detail__row">
        <span>Комментарий:</span> {{ order.comment }}
      </div>
    </div>
    <div class="order" v-for="order_goods in order.orders_1c" :key="order_goods.code_1c">
      <div class="order__title">
        <span>Номер заказа: {{ order_goods.code_1c }}</span>
        <span>{{ order_goods.response_1c.manager_FIO }}</span>
        <span>{{ order_goods.response_1c.manager_email }}</span>
        <span>{{ order_goods.response_1c.manager_phone }}</span>
      </div>
      <div class="order__list">
        <div class="order__item" v-for="(item, index) in order_goods.content_data" :key="index">
          <div class="order__pic">
            <img v-if="item.image_urls[0]" :src="item.image_urls[0]" alt="" class="order__img">
            <img v-else src="@/assets/img/empty.png" alt="" class="order__img">
          </div>
          <div class="order__body">
            <div class="order__title">{{ item.name }}</div>
            <div class="order__code">{{ item.category }} ({{ item.price_group }})</div>
            <div class="order__codes">
              <div class="order__code" v-if="item.series">{{ item.series }}</div>
              <div class="order__code" v-if="item.warehouse">{{ item.warehouse }}</div>
            </div>
            <div class="order__code characteristic" v-if="item.characteristic">{{ item.characteristic }}</div>
            <div class="order__detail">
              <div class="order__info" :class="{ 'order__info--red': item.price === null }">
                <div class="order__count">
                  {{ item.price === null ? '-' : item.price }} <span v-if="item.price !== null">₽</span>
                </div>
                <div class="order__text">за {{ item.basic_measurement }}</div>
              </div>
              <div class="order__info order__info--smooth">
                <div class="order__count">
                  {{ item.amount }} <span class="small">{{ item.basic_measurement }}</span>
                </div>
                <div class="order__text">кол-во товара</div>
              </div>
              <div v-if="item.total_price" class="order__info">
                <div class="order__count">
                  {{ item.total_price }} <span>₽</span>
                </div>
                <div class="order__text">сумма</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'orderDetail',
  data() {
    return {
      order: {
        client: { name: null },
        counterparty: { name: null },
        user: { fio: null },
        orders_1c: []
      },
      content_data: {
        characteristics_content: [],
        content: [],
      }
    }
  },
  mounted() {
    this.$store.dispatch('orders/GET_DATA', this.$route.params.id)
      .then(response => {
        this.order = response.data
        this.order.orders_1c.forEach(item => {
          item.content_data = JSON.parse(item.content_data)
          item.response_1c = JSON.parse(item.response_1c)
        })
      })
      .catch(error => {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: `Ошибка: ${ error.message }`
        })
      })
  },
  methods: {
    // round3(price, amount) {
    //   const total = price * amount
    //   return total.toFixed(3)
    // },
    goToClientDetail(client_id) {
      this.$router.push({ name: 'client-detail', params: { id: client_id } })
    },
    goToUserDetail(client_id, user_id) {
      this.$router.push({ name: 'user-detail', params: { id: client_id, user: user_id } })
    }
  }
}
</script>
